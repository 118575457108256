import "./index.scss";

/**
 * Import modules
 */

// import Inputmask from "inputmask/dist/inputmask.min.js";
import { Fancybox } from "@fancyapps/ui";
import Swiper from "swiper/bundle";
import lozad from "lozad";
import Toastify from "toastify-js";


/**
 * Helpers
 */
const cyrValue = (str) => {
  return /[^а-яА-ЯёЁ -]/gi.test(str);
};
const fancyboxShow = (src, type) => {
  Fancybox.show(
    [
      {
        src: src,
        type: type,
      },
    ],
    {
      autoFocus: false,
    }
  );
};
const addModalEvent = (selector) => {
  document.querySelectorAll(selector).forEach((el) => {
    if (el.classList.contains("modal-rendered") == false) {
      el.classList.add("modal-rendered");

      el.addEventListener("click", (evt) => {
        evt.preventDefault();

        const button = evt.currentTarget,
          target = button.hash || button.dataset.href,
          popup = document.querySelector(target),
          title = button.dataset.modalTitle,
          content = button.dataset.modalContent,
          footer = button.dataset.modalFooter,
          submit = button.dataset.modalSubmit,
          yaGoal = button.dataset.modalYagoal,
          email = button.dataset.modalEmail;

        // if (submit) popup.querySelector("input[type='submit']").value = submit;
        if (submit) popup.querySelector("button[type='submit']").value = submit;
        if (yaGoal) popup.querySelector("input[name='ya_goal']").value = yaGoal;
        if (email)
          popup.querySelector("input[name='email_title']").value = email;
        if (target) fancyboxShow(target, "inline");
      });
    }
  });
};
const toast = (title = "", text = "", type = "info") => {
  let message, messageTitle, messageText;
  message = document.createElement("div");
  messageTitle = document.createElement("p");
  messageTitle.classList.add("title");
  messageTitle.innerHTML = title;
  messageText = document.createElement("p");
  messageText.classList.add("text");
  messageText.innerHTML = text;
  message.append(messageTitle, messageText);

  Toastify({
    className: type,
    close: true,
    destination: false,
    duration: 3000,
    escapeMarkup: false,
    gravity: "bottom",
    position: "right",
    stopOnFocus: true,
    text: message.innerHTML,
    onClick: () => { },
  }).showToast();
};

/**
 * Modal
 */
if (document.querySelector(".modal-link")) addModalEvent(".modal-link");

/**
 * Fields validation
 */
if (document.querySelector("input[name='agreement']")) {
  document.querySelectorAll("input[name='agreement']").forEach((el) => {
    el.addEventListener("change", (evt) => {
      const status = evt.currentTarget.checked;

      if (status) {
        evt.currentTarget
          .closest("form")
          .querySelector("button")
          .removeAttribute("disabled");
      } else {
        evt.target
          .closest("form")
          .querySelector("button")
          .setAttribute("disabled", "");
      }
    });
  });
}
if (document.querySelector("input[required]")) {
  document.querySelectorAll("input[required]").forEach((el) => {
    el.addEventListener("blur", (evt) => {
      if (evt.target.value.length == 0) {
        toast("Внимание!", "Поле обязательно для заполнения", "WARNING");
      }
    });
  });
}
/**
 * Form
 */
if (document.querySelector("form.fetch")) {
  document.querySelectorAll("form.fetch").forEach((form) => {
    form.addEventListener("submit", (evt) => {
      evt.preventDefault();
      //let yaGoal = form.querySelector("input[name='ya_goal']").value;

      let phone = form.querySelector("input[name='phone']").value;

      if (phone.length[17] === "_" || phone.length === 0) {
        document.querySelector("input[name='phone']").classList.add("is-error");
      } else {

        if (form.querySelector("input[type='submit']"))
          form
            .querySelector("input[type='submit']")
            .setAttribute("disabled", "");
        if (form.querySelector("button[type='submit']"))
          form
            .querySelector("button[type='submit']")
            .setAttribute("disabled", "");

        let data = new FormData(form);
        mna100("email", data, (r) => {
          if (r.status === 1) {

            Fancybox.close();
            if (form.querySelector("input[type='submit']"))
              form
                .querySelector("input[type='submit']")
                .removeAttribute("disabled", "");
            if (form.querySelector("button[type='submit']"))
              form
                .querySelector("button[type='submit']")
                .removeAttribute("disabled", "");

            form.querySelectorAll("input").forEach((inp) => {
              if (
                inp.type !== "submit" &&
                inp.type !== "checkbox" &&
                inp.type !== "radio" &&
                inp.type !== "hidden"
              ) {
                inp.value = "";
                inp.parentNode.classList.remove("valid");
              }
            });
            if (form.querySelector("textarea")) {
              form.querySelector("textarea").value = "";
              form
                .querySelector("textarea")
                .parentNode.classList.remove("valid");
            }
            if (form.querySelector("input[type='checkbox']")) {
              form.querySelectorAll("input[type='checkbox']").forEach((el) => {
                if (el.name != "agreement") el.checked = false;
              });
            }

            setTimeout(() => {
              fancyboxShow("#thanks", "inline");
            }, 150);
            setTimeout(() => {
              Fancybox.close();
            }, 5000);

            if (typeof ym == "function") {
              ym("XXXXXXXX", "reachGoal", yaGoal);
              console.log("Цель достигнута: " + yaGoal);
            }
            if (typeof gtag == "function") {
              //gtag("event", "form_lead", {"event_category": "lead", "event_action": "zayavka"});
            }
          } else {
            toast("Внимание!", "Ошибка ! " + r.message, "WARNING");

            Fancybox.close();

          }
        });
      }
    });
  });
}

/**
 * Modal
 */
if (document.querySelector(".modal-link")) addModalEvent(".modal-link");

/**
 * Lazy load
 */
const observer = lozad();
observer.observe();

/**
 * Mobile menu
 */
if (document.querySelector(".header__humburger-btn")) {
  document.querySelector(".header__humburger-btn").addEventListener("click", (evt) => {
    let buttonClasslist = evt.currentTarget.classList;

    buttonClasslist.toggle("active");

    if (buttonClasslist.contains("active")) {
      document.querySelector(".header__mobile").classList.add("active");
      document.querySelector("body").style.overflow = "hidden";
      document.querySelector("html").style.overflow = "hidden";
    } else {
      document.querySelector(".header__mobile").classList.remove("active");
      document.querySelector("body").style.overflow = "auto";
      document.querySelector("html").style.overflow = "auto";
    }
  });
}
if (document.querySelector(".header__mobile .menu-item-has-children")) {
  document
    .querySelectorAll(".header__mobile .menu-item-has-children")
    .forEach((el) => {
      let row = document.createElement("div");
      row.classList.add("menu-arrow");
      el.querySelector("a").after(row);


      row.addEventListener("click", (evt) => {
        el.classList.toggle("active");
      });
    });
}

/**
 * Swiper 2 etc
 */
if (document.querySelector(".swiper.slava__swiper")) {
  new Swiper(".swiper.slava__swiper", {
    navigation: {
      nextEl:
        ".swiper-button-next.slava__swiper-next",
      prevEl:
        ".swiper-button-prev.slava__swiper-prev",
    },
    slidesPerView: "1",
  });
}

if (document.querySelector(".swiper.sunrise__swiper")) {
  new Swiper(".swiper.sunrise__swiper", {
    navigation: {
      nextEl:
        ".swiper-button-next.sunrise__swiper-next",
      prevEl:
        ".swiper-button-prev.sunrise__swiper-prev",
    },
    slidesPerView: "1",
  });
}

/**
 * Smooth scroll
 */
const anchors = document.querySelectorAll('a[href*="#"]')

for (let anchor of anchors) {
  anchor.addEventListener('click', function (e) {
    e.preventDefault()

    const blockID = anchor.getAttribute('href').substr(1)

    document.getElementById(blockID).scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  })
}

/**
 * Mobile menu
 */
if (document.querySelector("button.hamburger")) {
  document
    .querySelector("button.hamburger")
    .addEventListener("click", (evt) => {
      let buttonClasslist = evt.currentTarget.classList;

      buttonClasslist.toggle("active");
      if (buttonClasslist.contains("active")) {
        document.querySelector(".mobile").classList.add("active");
        document.querySelector("body").style.overflow = "hidden";
      } else {
        document.querySelector(".mobile").classList.remove("active");
        document.querySelector("body").style = "";
      }
    });
}
if (document.querySelector(".mobile")) {
  document.querySelectorAll(".mobile").forEach((i) => {
    i.addEventListener("click", () => {
      document.querySelector("button.hamburger").classList.remove("active");
      document.querySelector(".mobile").classList.remove("active");
      document.querySelector("body").style = "";
    });
  });
}

//phone mask
let phoneInputs = document.querySelectorAll("input[type='tel']");
let getInputNumbersValue = function (input) {
  return input.value.replace(/\D/g, "");
};
let onPhonePaste = function (e) {
  let input = e.target,
    inputNumbersValue = getInputNumbersValue(input);
  let pasted = e.clipboardData || window.clipboardData;
  if (pasted) {
    let pastedText = pasted.getData("Text");
    if (/\D/g.test(pastedText)) {
      input.value = inputNumbersValue;
      return;
    }
  }
};
let onPhoneInput = function (e) {
  let input = e.target,
    inputNumbersValue = getInputNumbersValue(input),
    selectionStart = input.selectionStart,
    formattedInputValue = "";

  if (!inputNumbersValue) {
    return (input.value = "");
  }
  if (input.value.length != selectionStart) {
    if (e.data && /\D/g.test(e.data)) {
      input.value = inputNumbersValue;
    }
    return;
  }
  if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
    if (inputNumbersValue[0] == "9")
      inputNumbersValue = "7" + inputNumbersValue;
    let firstSymbols = inputNumbersValue[0] == "8" ? "8" : "+7";
    formattedInputValue = input.value = firstSymbols + " ";

    if (inputNumbersValue.length > 1) {
      formattedInputValue += "(" + inputNumbersValue.substring(1, 4);
      this.classList.add("is-invalid");
      document.getElementById('submit-btn').classList.add("disabled");
    }
    if (inputNumbersValue.length >= 5) {
      formattedInputValue += ") " + inputNumbersValue.substring(4, 7);
    }

    if (inputNumbersValue.length >= 8) {
      formattedInputValue += "-" + inputNumbersValue.substring(7, 9);
    }

    if (inputNumbersValue.length >= 10) {
      formattedInputValue += "-" + inputNumbersValue.substring(9, 11);
    }

    if (inputNumbersValue.length >= 11) {
      this.classList.remove("is-invalid");
      this.classList.add("is-valid");
      document.getElementById('submit-btn').classList.remove("disabled");
    }
  } else {
    formattedInputValue = "+" + inputNumbersValue.substring(0, 16);
  }
  input.value = formattedInputValue;
};
let onPhoneKeyDown = function (e) {
  let inputValue = e.target.value.replace(/\D/g, "");
  if (e.keyCode == 8 && inputValue.length == 1) {
    e.target.value = "";
  }
};
for (let phoneInput of phoneInputs) {
  phoneInput.addEventListener("keydown", onPhoneKeyDown);
  phoneInput.addEventListener("input", onPhoneInput, false);
  phoneInput.addEventListener("paste", onPhonePaste, false);
}


//capitalize
function capitalize(input) {
  input.value = input.value.replace(/( |^)[а-яёa-z]/g, function (u) {
    return u.toUpperCase();
  });
}
document
  .querySelector("input[name='name']")
  .addEventListener("input", function () {
    capitalize(this);
  });

function capitalizeModal(input) {
  input.value = input.value.replace(/( |^)[а-яёa-z]/g, function (u) {
    return u.toUpperCase();
  });
}
document
  .querySelector(".validate__name")
  .addEventListener("input", function () {
    capitalizeModal(this);
  });

//regex letters
let nameValidate = document.querySelector("input[name='name']");
let regnumberform = /[0-9]/g;
nameValidate.oninput = function () {
  if (this.value.match(regnumberform)) {
    this.value = this.value.replace(regnumberform, "");
  } else {
    this.classList.add("is-valid");
  }
};

//mail validation
const EMAIL_REGEXP =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
const input = document.querySelector("input[type='email']");

function isEmailValid(value) {
  return EMAIL_REGEXP.test(value);
}

function onInput() {

  if (isEmailValid(input.value)) {
    this.classList.remove('is-invalid');
    this.classList.add('is-valid');
    document.getElementById('submit-btn').classList.remove("disabled");

  } else {
    this.classList.add('is-invalid');
    document.getElementById('submit-btn').classList.add("disabled");
  }
}

input.addEventListener('input', onInput);
